"use client";

import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import Link from "next/link";
import React, { Suspense, useRef, useState } from "react";

import { BurgerIcon } from "@/components/Icon/Burger/Bars3Icon";
import { CloseLineIcon } from "@/components/Icon/Close/CloseLineIcon";
import { FilialFinderIcon } from "@/components/Icon/FilialFInderIcon";
import { LeafletIcon } from "@/components/Icon/LeafletIcon";
import { Logo as LogoSvg } from "@/components/Icon/Logo";
import { CategoryDropdown, CategoryDropdownSkeleton } from "@/components/Menu/CategoryDropdown";
import { WishlistMenu } from "@/components/Menu/components/Wishlist";
import SearchInput from "@/components/Menu/SearchInput";
import { SearchComponent } from "@/components/Search";
import { MenuLink } from "@/data/Menu";
import { useLayout } from "@/lib/contexts/layout-context";
import { SIDEBAR_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";

const Logo = ({ className, onClick }: { className?: string; onClick?: () => void }) => (
  <Link href="/" onClick={onClick}>
    <LogoSvg className={className} />
  </Link>
);

const RenderCategoryBadges = ({ show = true, children }: React.PropsWithChildren<{ show?: boolean }>) => {
  if (show) {
    return <div className="xl:pt-3 overflow-x-hidden pr-5">{children}</div>;
  }

  return null;
};

export default function Menu({ links, categories }: { links: MenuLink[]; categories: MenuLink[] }) {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const initialRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <header
        ref={headerRef}
        className={clsx("bg-white shadow-md w-full sticky z-50 top-0 transition-all duration-300 ease-in-out border-b-4 border-b-kodi-magenta md:border-b-0")}
      >
        <div className="hidden md:block bg-kodi-magenta h-1 w-full"></div>
        <div ref={initialRef}>
          <InitialMenu links={links} categories={categories} />
        </div>
      </header>
    </>
  );
}

const InitialMenu = ({ links, categories }: { links: MenuLink[]; categories: MenuLink[] }) => {
  const { close } = useSideModal();
  const { handleSearching, isSearching } = useLayout();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleSearch = () => {
    handleSearching(!isSearching);
    close();
  };

  return (
    <div className="bg-white max-w-[1500px] mx-auto py-5 px-5 lg:px-8">
      {!isSearching ? (
        <MenuItems links={links} categories={categories} handleSearch={handleSearch} searchValue={searchValue} setSearchValue={setSearchValue} />
      ) : (
        <>
          <div className="md:block hidden">
            <MenuItems links={links} categories={categories} handleSearch={handleSearch} searchValue={searchValue} setSearchValue={setSearchValue} />
          </div>

          <div className="md:hidden block">
            <div className="flex items-center">
              <SearchInput onChange={(event) => setSearchValue(event.target.value)} placeholder="Suchen" value={searchValue} className="w-full" />
              <CloseLineIcon className="w-7 h-7 cursor-pointer" onClick={() => handleSearching(!isSearching)} />
            </div>
          </div>
        </>
      )}

      <SearchComponent searchValue={searchValue} setSearchValue={setSearchValue} />
    </div>
  );
};

interface MenuItemsInterface {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  links: MenuLink[];
  handleSearch: () => void;
  categories: MenuLink[];
}

const MenuItems = ({ searchValue, links, handleSearch, setSearchValue, categories }: MenuItemsInterface) => {
  const { showCategories } = useLayout();
  const {
    setModal,
    modal: { isShow, name },
  } = useSideModal();
  const { handleSearching } = useLayout();
  const isShowSidebar = name === SIDEBAR_MODAL && isShow;

  return (
    <div className="w-full flex items-center justify-between gap-x-2 lg:gap-x-5">
      <div className="flex flex-row items-center gap-x-5">
        <BurgerIcon
          className="w-5 text-gray-500 cursor-pointer lg:hidden"
          onClick={() => {
            setModal({ name: SIDEBAR_MODAL, isShow: !isShowSidebar });
            handleSearching(false);
          }}
        />
        <MagnifyingGlassIcon className="w-5 text-gray-500 ml-auto mr-2 md:hidden cursor-pointer" onClick={() => handleSearch()} />
        <Logo className="w-[6rem] md:w-[9rem] lg:w-[11rem] h-auto" onClick={() => handleSearching(false)} />
      </div>
      <div className="flex flex-col w-full md:pl-5">
        <div className="flex justify-end">
          <div className="hidden md:block w-full pr-10">
            <SearchInput onChange={(event) => setSearchValue(event.currentTarget.value)} placeholder="" value={searchValue} className="w-full" />
          </div>
          <div className="flex items-center gap-x-1 md:gap-x-4 whitespace-nowrap">
            <Link className="group text-gray-600 w-full flex items-center" href={"/stores"} onClick={() => handleSearching(false)} aria-label={"Filialfinder"}>
              <FilialFinderIcon className="w-10 mr-0.5 stroke-gray-600" />
              <span className="ml-1 !text-sm lg:text-lg hidden lg:inline-block group-hover:underline">Filialfinder</span>
            </Link>
            <div className="text-gray-600 w-full flex items-center mr-0.5">
              <WishlistMenu />
            </div>
            <Link className="group text-gray-600 w-full flex items-center" href={"/catalog"} onClick={() => handleSearching(false)} aria-label="Prospekte">
              <LeafletIcon className="w-7 mr-0.5 stroke-[4px] stroke-gray-600" />
              <span className="ml-2 !text-sm lg:text-lg hidden lg:inline-block group-hover:underline">Prospekte</span>
            </Link>
          </div>
        </div>
        <div className="lg:max-w-[75vw] overflow-x-hidden">
          <RenderCategoryBadges show={showCategories}>
            <Suspense fallback={<CategoryDropdownSkeleton />}>
              <CategoryDropdown links={links} categories={categories} />
            </Suspense>
          </RenderCategoryBadges>
        </div>
      </div>
    </div>
  );
};

"use client";

import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import clsx from "clsx";
import { useCart, useProducts } from "medusa-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useCallback, useEffect, useState } from "react";

import { WishListIcon } from "@/components/Icon/Heart/WishListIcon";
import { TrashIcon } from "@/components/Icon/Trash";
import { WishlistTotal } from "@/components/Menu/components/WishlistTotal";
import { useProductPrice } from "@/hook/product/product-price-hook";
import { medusaClient } from "@/lib/config";
import { useLayout } from "@/lib/contexts/layout-context";
import { useWishlist } from "@/lib/contexts/wishlist-context";
import { Region } from "@/types/medusa";

export const WishlistItemRow = ({ product, children, className }: { product: PricedProduct; children?: React.ReactNode; className?: string }) => {
  const [isShow, setShow] = useState<boolean>(false);

  return (
    <div
      className={clsx("flex justify-between items-center border-b-2 border-dotted border-b-white mb-4", { className: className?.length })}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div className="flex items-center gap-x-2 w-[70vw] sm:w-[50vw] md:w-[40vw]">
        {isShow && children}
        <div className="flex items-center justify-between w-full">
          <Link href={`/p/${product.handle}`} className={"hover:underline"}>
            <div className="text-sm md:text-base mr-10">{product.title}</div>
          </Link>
          <ProductPrice product={product} />
        </div>
      </div>
    </div>
  );
};

export const WishlistMenu = () => {
  const [isShow, setShow] = useState<boolean>(false);
  const [region, setRegion] = useState<Region | null>(null);
  const { wishlist, refetch, removeProduct } = useWishlist();
  const { handleSearching } = useLayout();
  const pathName = usePathname();

  const isWishlist = pathName === "/wishlist";

  useEffect(() => {
    if (!wishlist) {
      refetch();
    }

    medusaClient.client.request("GET", "/store/regions").then(({ regions }) => {
      setRegion(regions.at(0));
    });
  }, [wishlist]);

  useEffect(() => {
    if (isWishlist) {
      setShow(false);
    }
  }, [isWishlist]);

  const WishlistItems = useCallback(() => {
    return (
      <>
        {wishlist?.items?.map(({ product }, index) => {
          return (
            <WishlistItemRow product={product} key={index}>
              <TrashIcon className="w-5 h-5 cursor-pointer" onClick={() => removeProduct(product.id ?? "")} />
            </WishlistItemRow>
          );
        })}

        {!wishlist?.items?.length && (
          <div className="flex justify-between items-center border-b-2 border-dotted border-b-white mb-2">
            <p>Keine Produkte gefunden</p>
          </div>
        )}
      </>
    );
  }, [wishlist]);

  const RenderTotal = useCallback(() => {
    return <WishlistTotal wishlist={wishlist} region={region} />;
  }, [wishlist, wishlist?.items, region]);

  return (
    <div className="flex items-center" onMouseLeave={() => setShow(false)}>
      <Link
        className="group text-gray-600 w-full flex items-center"
        href={"/wishlist"}
        onMouseEnter={() => setShow(true)}
        onClick={() => {
          setShow(false);
          handleSearching(false);
        }}
      >
        <WishListIcon
          className={clsx("w-10 mr-0.5 cursor-pointer stroke-[5px] fill-none transition-all duration-300", {
            "stroke-gray-600": !isWishlist,
            "stroke-kodi-blue": isWishlist,
          })}
        />
        <span
          className={clsx("ml-0.5 !text-sm lg:text-lg hidden lg:inline-block group-hover:underline !break-keep", {
            "text-kodi-blue": pathName === "/wishlist",
          })}
        >
          Wunschliste
        </span>
      </Link>

      {isShow && !isWishlist && (
        <div className="relative z-10">
          <div className="bg-kodi-blue -right-7 -top-8 absolute text-white rounded-lg min-w-[20rem] px-5 py-2">
            <Link className="group flex flex-row items-center justify-end mb-5" href={"/wishlist"}>
              <WishListIcon className="w-10 cursor-pointer stroke-[5px] fill-none transition-all duration-300 stroke-white" />
              <span className="ml-1 text-md group-hover:underline">Wunschliste</span>
            </Link>
            <div className="w-full">
              <WishlistItems />
              <div className="flex justify-between items-center border-b-2 border-dotted border-b-white mb-0.5 mt-7">
                <p></p>
                <RenderTotal />
              </div>
              <div className="border-b-2 border-dotted border-b-white w-full mb-14"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ProductPrice = ({ product }: { product: PricedProduct }) => {
  const pricedProduct = usePricedProduct(product);
  const { getCheapestPrice, toLocalString } = useProductPrice({ product: pricedProduct });
  const price = getCheapestPrice()?.calculated_price;

  if (price) {
    return <p className="text-base md:text-2xl font-bold">{toLocalString(price)}</p>;
  }

  return <p className="text-base md:text-2xl font-bold">__*</p>;
};

export const usePricedProduct = (product: PricedProduct) => {
  const { cart } = useCart();
  const { products } = useProducts(
    {
      id: product.id,
      region_id: cart?.region_id,
      expand: "variants,variants.prices",
    },
    { enabled: !!cart?.region_id },
  );

  return products?.at(0);
};

import React from "react";

import { IconInterface } from "@/components/Icon/IconInterface";

const commonStrokeStyle: React.CSSProperties = {
  fill: "none",
};

const st0: React.CSSProperties = {
  ...commonStrokeStyle,
  fill: "currentColor",
};

const st1: React.CSSProperties = {
  ...commonStrokeStyle,
  stroke: "currentColor",
  strokeWidth: 5,
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 10,
};

export const LeafletIcon = ({ className, width, height, fill }: IconInterface) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113.39 113.39" className={className} width={width} height={height} fill={fill}>
    <g>
      <path
        style={st0}
        d="M92.69,14.58c3.63-1.48,6.85-2.77,10.06-4.09c2.48-1.02,4.5,0.34,4.5,3.02c0.02,25.04,0.01,50.08,0.07,75.12
		c0,2.1-0.74,3.22-2.7,4.04c-15.23,6.31-30.4,12.75-45.65,19.02c-1.25,0.51-3.3,0.51-4.55,0c-15.17-6.23-30.27-12.65-45.43-18.91
		c-2.16-0.89-2.92-2.11-2.91-4.42c0.07-24.88,0.04-49.75,0.05-74.63c0-4.12,0.93-4.73,4.76-3.14c3.06,1.27,6.12,2.52,9.72,4.01
		c0-3.46-0.11-6.49,0.05-9.5c0.04-0.81,0.34-1.6,0.64-2.39c0.28-0.72,1.06-1.12,1.81-0.93c0.9,0.23,1.8,0.47,2.45,1.01
		c9.76,8.18,19.47,16.41,29.07,24.77c1.71,1.49,2.65,1.3,4.22-0.06c9.43-8.17,18.95-16.24,28.44-24.34
		c0.52-0.44,0.98-1.04,1.58-1.28c0.81-0.32,1.9-0.73,2.56-0.43c0.63,0.3,1.19,1.44,1.22,2.23C92.76,7.14,92.69,10.63,92.69,14.58z
		 M10.52,15.12c-0.05,0.87-0.06,1.39-0.06,1.81c0,23.24,0.02,46.47-0.03,69.71c0,1.57,0.58,2.24,1.94,2.8
		c13.25,5.5,26.47,11.05,39.7,16.58c0.66,0.28,1.37,0.44,2.2,0.69c0.09-0.71,0.18-1.12,0.18-1.54c0.01-23.24-0.01-46.47,0.05-69.71
		c0-1.59-0.67-2.21-2.01-2.77c-9-3.71-17.96-7.5-26.94-11.26C20.7,19.41,15.74,17.31,10.52,15.12z M58.97,106.85
		c1.1-0.39,1.98-0.67,2.83-1.02c12.84-5.37,25.65-10.81,38.53-16.08c2.09-0.85,2.75-1.91,2.74-4.13
		c-0.08-22.47-0.05-44.95-0.05-67.42c0-0.88-0.1-1.76-0.17-2.88c-1,0.35-1.64,0.53-2.25,0.79c-13.15,5.5-26.29,11.05-39.47,16.48
		c-1.82,0.75-2.33,1.72-2.33,3.61c0.07,22.64,0.04,45.29,0.05,67.93C58.84,104.95,58.92,105.77,58.97,106.85z M42.89,23.91
		c0.16-0.27,0.12-0.2,0.28-0.46c-5.88-5.01-11.76-10.02-18.2-15.52c0,2.68,0.11,4.54-0.03,6.38c-0.13,1.69,0.57,2.51,2.16,3.09
		c2,0.73,3.98,1.49,5.91,2.37C36.26,21.27,39.58,22.57,42.89,23.91z M88.31,7.99c-6.36,5.44-12.11,10.35-17.86,15.26
		c0.12,0.19,0.24,0.39,0.36,0.58c5.53-2.32,11.08-4.62,16.58-7.02c0.45-0.2,0.85-1.05,0.88-1.61C88.38,13.09,88.31,10.97,88.31,7.99
		z"
      />
      <path
        style={st0}
        d="M65.65,50.64c0.2-8.76-1.51-6.32,6.56-10.24c6.83-3.32,13.76-6.43,20.65-9.63c3.68-1.7,4.67-1.09,4.67,2.86
		c0,3.16,0.15,6.33-0.1,9.47c-0.08,1.04-0.94,2.47-1.84,2.91c-8.68,4.2-17.43,8.26-26.19,12.29c-2.43,1.12-3.7,0.19-3.76-2.54
		c-0.02-0.85,0-1.71,0-2.56C65.65,52.35,65.65,51.5,65.65,50.64z M70.52,52.96c7.29-3.39,14.55-6.76,21.77-10.18
		c0.44-0.21,0.89-0.88,0.92-1.36c0.11-1.82,0.06-3.82,0.06-5.97c-1.1,0.41-1.76,0.76-2.35,1.04c-5.34,2.48-10.68,4.97-16.01,7.47
		c-5.34,2.5-5.33,2.51-4.8,8.37C70.12,52.39,70.16,53.11,70.52,52.96z"
      />
    </g>
    <line style={st1} x1="15.12" y1="29.06" x2="47.87" y2="42.61" />
    <line style={st1} x1="15.12" y1="43.74" x2="47.87" y2="57.3" />
    <line style={st1} x1="77.58" y1="77.57" x2="67.64" y2="81.33" />
    <line style={st1} x1="74" y1="87.97" x2="67.64" y2="90.41" />
  </svg>
);

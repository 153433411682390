"use client";

import clsx from "clsx";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import { HeaderNoticePosition, PageType, StrapiHeaderNotice } from "@/data/strapi";
import { useLayout } from "@/lib/contexts/layout-context";

interface Props {
  position: HeaderNoticePosition;
}

export const pageTypeByPath = (path: string): PageType => {
  if (path === "/") {
    return PageType.Home;
  }

  if (path.startsWith("/account")) {
    return PageType.Account;
  }

  if (path.startsWith("/checkout")) {
    return PageType.Checkout;
  }

  if (path.startsWith("/p/")) {
    return PageType.Product;
  }

  if (path.startsWith("/c/")) {
    return PageType.Category;
  }

  if (path.startsWith("/b/")) {
    return PageType.Brand;
  }

  if (path.startsWith("/k")) {
    return PageType.Campaign;
  }

  if (path.startsWith("/s")) {
    return PageType.Search;
  }

  return PageType.All;
};

export default function HeaderNotice({ position }: Props): React.JSX.Element | null {
  const { headerNotices, pageType, setPageType } = useLayout();
  const [visibleNotice, setVisibleNotice] = useState<StrapiHeaderNotice | undefined>(undefined);
  const pathName = usePathname();

  useEffect(() => {
    setPageType(pageTypeByPath(pathName));
  }, [pathName, setPageType]);

  useEffect(() => {
    const activeNotice = headerNotices.find((notice) => {
      const now = new Date();
      const start = notice.attributes.startingAt ? new Date(notice.attributes.startingAt) : new Date();
      const end = notice.attributes.endingAt ? new Date(notice.attributes.endingAt) : new Date();

      const isActiveByTime = now >= start && now <= end;
      const isActiveByPageType = notice.attributes.pageType === PageType.All || notice.attributes.pageType === pageType;
      const isActiveByPosition = notice.attributes.position === position;

      return isActiveByTime && isActiveByPageType && isActiveByPosition;
    });

    setVisibleNotice(activeNotice);
  }, [headerNotices, pageType, position]); // Ensure this useEffect runs only when dependencies change

  if (visibleNotice) {
    return (
      <div
        className={clsx("text-center sticky z-50 headNotice min-h-[30px] text-lg p-0.5", {
          "bg-kodi-magenta": !visibleNotice.attributes.backgroundColor,
          "text-white": !visibleNotice.attributes.textColor,
        })}
        style={{
          ...(visibleNotice.attributes.backgroundColor && { backgroundColor: visibleNotice.attributes.backgroundColor }),
          ...(visibleNotice.attributes.textColor && { color: visibleNotice.attributes.textColor }),
        }}
      >
        {visibleNotice.attributes.icon?.data?.attributes?.url && (
          <svg width="25" height="25" className={"inline-block m-[5px]"}>
            <image xlinkHref={`${process.env.STRAPI_URL ?? ""}${visibleNotice.attributes.icon.data.attributes.url}`} width="25" height="25" />
          </svg>
        )}
        <div dangerouslySetInnerHTML={{ __html: visibleNotice.attributes.message }} />
      </div>
    );
  }
  return null;
}

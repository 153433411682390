"use client";

import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { computeAmount } from "medusa-react";

import { CalculatedVariant } from "@/hook/product/product-price-hook";
import { WishlistInterface } from "@/lib/contexts/wishlist-context";
import { Region } from "@/types/medusa";

export const WishlistTotal = ({ wishlist, region }: { wishlist: WishlistInterface | null; region: Region | null }) => {
  const total = wishlist?.items.reduce((acc, current) => {
    return acc + calculateOriginalPrice(current.product);
  }, 0);

  if (!region || !total) {
    return <p className="text-2xl font-bold">0,00</p>;
  }

  return (
    <p className="text-base md:text-2xl font-bold">
      <Price total={total} region={region} />
    </p>
  );
};

const Price = ({ total, region }: { total: number; region: Region }) => {
  return computeAmount({
    amount: total,
    region: region,
    includeTaxes: false,
  }).toLocaleString();
};

const calculateOriginalPrice = (product: PricedProduct): number => {
  if (!product || !product?.variants?.length) {
    return 0;
  }

  const cheapestVariant = (product.variants as CalculatedVariant[]).reduce((prev, curr) => {
    return prev.calculated_price < curr.calculated_price ? prev : curr;
  });

  const price = cheapestVariant?.prices?.at(0)?.amount;
  return price ? price : 0;
};
